export default {
    title: {
      report: 'REPORT\'S LIST',
      user: 'USER\'S LIST',
      superadmin: 'LIST OF PISTAMATIC AIRPORTS',
      admin_settings: 'SETTINGS',
      product: 'PISTAMATIC',
      edit_personal: 'EDIT PERSONAL USER INFORMATION',
      perso_basic: 'BASIC INFORMATION',
      perso_pass: 'UPDATE PASSWORD',
      add_user: 'ADD NEW USER',
      update_user: 'UPDATE INFORMATION ABOUT USER',
      airport_settings: 'UPDATE SETTING DATA FOR AIRPORT IN USE',
      log: 'LOG VIEWER:',
      contact_form: 'CONNECT WITH US',
      add_mail: 'ADD NEW MAIL INFORMATION',
      update_mail: 'EDIT MAIL INFORMATION',
      notification: 'ADMINISTATOR\'S SETTINGS',
      message_center: 'MESSAGE CENTER',
      visitor: 'LIST OF VISITORS',
      wl_stat: 'WILDLIFE STATISTICS',
      wl_stat_time: 'TIME PERIOD',
      wl_stat_animal: 'TYPE OF ANIMALS',
      wl_stat_airport: 'AIRPORT (SA)',
      duration: 'REPORT\'S DURATION',
      birdsstrike: 'BIRD STRIKE REPORTING FORM',
      tracker: 'TRACKING',
      paas: 'AIRPORT ALERTING SYSTEM',
      create_alert: 'FORM FOR CREATING ALERT: ',
      update_create_alert: 'UPDATE ALERT\'S INFORMATION',
      contact_list: 'CONTACT\'S LIST',
      contact_add:'ADD NEW CONTACT',
      contact_update:'UPDATE CONTACT INFORMATION',
      paas_stopped:'ALERTS HISTORY',
      wlstat_listing:'WILDLIFE REPORTS SUMMARY'
    },
    menu: {
        public: {
            home: 'HOME',
            about: 'ABOUT PRODUCTS',
            contact: 'CONTACT'
        },
        private: {
            reports: 'REPORTS',
            users: 'USERS',
            admin_settings: 'SETTINGS',
            notification: 'SETTINGS',
            settings: 'SETTINGS',
            runway: 'AIRPORT MAP',
            message: 'MESSAGES',
            wlstat: 'CHARTS',
            duration: 'DURATION',
            birdsstrike: 'BIRDS STRIKE',
            wildlife: 'WLS',
            tracker: 'TMS',
            trackerLive: 'TMSlive',
            paas: 'PAAS',
            listing: 'REPORTS SUMMARY'
        },
        dropdown: {
            account: 'ACCOUNT',
            help: 'HELP',
            logout: 'LOG OUT',
            visitors: 'VISITORS',
            license: 'LICENSE'
        }
    },
    general: {
        company1: 'SESTEMA',
        company2: 'DWC LLC',
        pp: 'Privacy Policy',
        mail: 'MAIL.',
        value_mail: 'info@pistamatic.com',
        mob: 'MOB.',
        value_mob: '+387 63 489 705',
        text1: 'WELCOME TO',
        text2_1: 'The PISTAMATIC application appears in the market as a powerful tool for creating all daily reports required by the airport authority efficiently and in the shortest possible time, thereby reducing the workload of operators who perform situation assessments, as well as of responsible persons in the air traffic control (ATCO’s).',
        text2_2: 'It consists of a mobile application realized on the modern tablet device, delivered with the application and the web part providing access for all other users, as defined by the airport administrator.',
        
        RWY_text1: 'PISTAMATIC RWY REPORT',
        RWY_text2: 'By using the PISTAMATIC app, there is no longer any need for the voice communication via radio channel between the airport operator and the air traffic control officer, since now the controller can have all the information at the same moment when the situation is assessed.',
        RWY_text3: 'Another important fact that should be highlighted concerns the communication that so far had to be printed, signed, distributed, and stored in the form of paper reports, which is now completely paperless via PISTAMATIC app.',
        RWY_text4: 'Now it is saved without user intervention on fully secure redundant servers, allowing users to search and open reports for five years from the moment of creation by simply viewing them through the web application.',
        RWY_text5: 'The PISTAMATIC RWYREPORT creates four reports, three of which are defined by ICAO and EASA regulations (ICAO Annex 14, Vol I, para 2.9.1 and ATT A para 6.2.9.1, ICAO Doc 9981, para 2.1 and 3.3, EASA Regulation (EU ) No 139/2014):',
        RWY_text6: 'AIRPORT DAILY SELF-INSPECTION REPORT:',
        RWY_text7: 'Sheduled report of the condition on the maneuvering areas and VISAIDs assets',
        RWY_text8: 'RCR:',
        RWY_text9: 'Report based on the new ICAO guidelines defined as GRF (Global Reporting Format)',
        RWY_text10: 'SPECIAL AIRPORT DAILY SELF-INSPECTION REPORT:',
        RWY_text11: 'Special report of the condition on the maneuvering areas and VISAIDs assets',
        RWY_text12:'REPORT ON DAMAGE: ',
        RWY_text13: 'Report of the damage on maneuvering surfaces and VISAID (this report is an add-on, which enables imaging and georeferencing of damage and automatic report generation)',
        RWY_text14: 'All the reports are associative with plenty of graphics and in line with the concept of existing paper reports, so switching to the use of tablets does not require a large burden for operators. The application guides the operator through the application by opening the required fields and allowing the entry of terms and sizes that are possible.',

        WEB_text1: 'PISTAMATIC WEB',
        WEB_text2: 'We are proud of our web app, which users especially like, because, among other things, just one look at the airport map gives them all the necessary information.',
        WEB_text3: 'The WEB part of the application provides support for the mobile app (mostly invisible to users such as log files and other information that is exchanged).',
        WEB_text4: 'Thanks to the PISTAMATIC RWYREPORT mob app, ATCO\'s and ARO service use in a simple way exclusively the web app to confirm reception of the condition of maneuvering area and the airport\'s lighting systems information, as well as to forward RCR information in the form of SNOWTAM to the AMHS network.',
        WEB_text5: 'For the purpose of effective protection of the air operations at the airport, the web app creates numerous graphic diagrams, which enable airport operators to efficiently prevent the appearance of WL (WildLife) on airport maneuvering areas.',

        WL_text1: 'PISTAMATIC WL REPORT',
        WL_text2: 'Recently, the PISTAMATIC family has been extended for the new WLR (WildLife Report) app.',
        WL_text3: 'Based on experience and observing the existing solutions and their shortcomings, we created an application format that should meet all the needs of the airport, both for the current information from manoeuvring areas and the multitude of statistical information that the web part offers.',
        WL_text4: 'Operators can now in a simple PISTAMATIC way fill out the report form with all the data that are important for the effective wildlife management.',
        WL_text5: 'The WLR app is highly adapted in all segments according to the each user, i.e. local conditions and airport environment.',
        WL_text6: 'For the WLR web segment, it is also important to emphasize that all the information gathered from the reports are used for the powerful statistical analysis through the illustrative graphs.',
        WL_text7: 'PISTAMATIC Wildlife report got new opportunities and tools, namely now with the creation of the report, the operator can directly from the application, for the purpose of dispersing birds and wild animals from manoeuvring areas, include a distress call, as well as predator advertising, all in accordance with the coordinated list of animals and predators for a particular airport.',
        WL_text8: 'Along with all the application solutions in the creation of reports, for the needs of dispersing animals from the manoeuvring areas of the airport, PISTAMATIC also offers the possibility of delivering a megaphone that is connected to the application via Bluetooth and does not require any wiring.',

        text13: 'INTERCONNECTED REPORTS',
        text14: 'The reports are interconnected and, if necessary, the data from one report is automatically loaded into another by the application. Reports are sent to predefined addresses. An integral part of an app is web part that allows user to manage the application as well as access, store and search reports.'
    },
    contact_form: {
        name: 'FULL NAME',
        mail: 'EMAIL',
        airport: 'AIRPORT NAME',
        message: 'MESSAGE'
    },
    login: {
        text1: 'Welcome to',
        text2: 'APPLICATION',
        username: 'USERNAME',
        pass: 'PASSWORD'
    },
    button: {
        search: 'SEARCH',
        create: 'CREATE',
        reset: 'RESET',
        send: 'SEND',
        preview: 'PREVIEW',
        delete: 'DELETE',
        view: 'VIEW',
        log: 'LOG',
        edit: 'EDIT',
        signin: 'SIGN IN',
        website: 'WEBSITE',
        change: 'CHANGE',
        cancel: 'CANCEL',
        save: 'SAVE',
        back: 'BACK',
        contact: 'CONTACT',
        login: 'LOGIN',
        get: 'GET IT NOW',
        send_mes: 'SEND MESSAGE',
        rcr: 'RCR',
        reportI: 'ADSIR',
        full: 'FULL',
        report_wlr: 'WLR',
        details: 'DETAILS',
        accept: 'ACCEPT',
        stop: 'STOP',
        yes: 'YES',
        no: 'NO',
        update: 'UPDATE',
        return: 'RETURN'
    },
    pagination: {
        next: 'NEXT',
        previous: 'PREVIOUS'
    },
    message: {
        license: {
            m1: 'Your license expires on: '
        },
        user: {
            m1: 'You successfully send user information...',
            m2: 'Problem to send information about password! Please, contact administrator!',
            m3: 'Please, add minimal one search request!',
            m4: 'User deleted successfully',
            m5: 'User temporarly deleted successfully',
            m6: 'Please, add airport!'
        },
        report: {
            m1: 'Problem to preview this report! Please, contact administrator...',
            m2: 'Report deleted successfully',
            m3: 'Please, add date period for search!',
            m4: 'You don\'t have permission to change status of field!'
        },
        superadminsettings: {
            m1: 'Please, fill all fields!',
            m2: 'Airport added for using successfully',
            m3: 'This airport has already been added!',
            m4: 'Please, choose airport for seraching!',
            m5: 'ADD ERROR LOG FILE!'
        },
        mailing_list: {
            m1: 'Mail information deleted successfully',
            m2: 'Please, choose airport!'
        },
        login: {
            m1:'You enter incorrect usarname/password or you dont have permission to access. Please, contact your administrator...',
            m2:'Please, contact administrator'
        },
        personal: {
            m1: 'This username already exists, please, change value...',
            m2: 'Personal basic information updated successfully',
            m3: 'Your current password is wrong. Please, enter again...',
            m4: 'Password updated successfully',
            m5: 'Problem to send information about update password! Please, contact administrator!',
            m6: 'Value for new password and confirm password must be the same. Please, enter again...'
        },
        usermanipulation: {
            m1: 'This username or email already exists, please, change value...',
            m2: 'User updated successfully',
            m3: 'This username or email already exists, please, change value...',
            m4: 'User added successfully',
            m5: 'Problem to send login information for new user! Please, contact administrator!'
        },
        airport_settings: {
            m1: 'Using airport updated successfully'
        },
        log: {
            m1: 'Successfully deleted log'
        },
        contact_form: {
            m1: 'YOU SEND MESSAGE SUCCESSFUL...'
        },
        mail_manipulation: {
            m1: 'Mail information updated successfully',
            m2: 'New mail information inserted successfull'
        },
        notification: {
            m1: 'Email configuration updated successfully',
            m2: 'You want to save option: NOT SEND EMAIL NOTIFICATION!'
        },
        message_center: {
            m1: 'Email send successfully'
        },
        airport_map: {
            m1: 'Problem to preview this report! Please, contact administrator...',
            m2: 'RCR is expired!'
        },
        wl: {
            m1: 'Please, choose type of animal and time period!',
            m2: 'NO DATA FOR SEARCHED ANIMAL'
        },
        birdsstrike: {
            m1: 'Birds Strike report sent successfully'
        },
        sos: {
            m1: 'Please, select time period!',
            m2: 'Please, selected time period is not regular!'
        },
        alert: {
            m1:'You successfully create alert...',
            m2:'You successfully update alert\'s information...',
            m3:'You successfully update coordinates...',
            m4:'You successfully stopped alert...',
            m5:'Alert trigger time value is not correct. This value must be after Time of occurence value!!!',
            m6:'Time of occurence value is not correct. This value must be before Alert trigger time value!!!',
            m7:'You successfully update field...',
        },
        paas_contact: {
            m1: 'New contact information inserted successfull',
            m2: 'Contact information updated successfully',
            m3: 'Contact deleted successfully',
            m4: 'Please, choose contact\'s type!',
            m5: 'Format of phone number is not correct!'
        },
        correction_LQSA: {
            m1: 'Please, fill value for RCR or ADSIR. Mimimal number is 0!',
            m2: 'RCR and ADSIR number updeted successfully'
        }
    },
    report: {
        search: {
            date: 'DATE',
            type:'REPORT TYPE',
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            type: 'TYPE',
            airport: 'AIRPORT',
            operator: 'OPERATOR',
            date: 'DATE',
            date_approved: 'ATC VIEW TIME',
            approvedby: 'APPROVED BY',
            approved: 'APPROVED',
            options: 'OPTIONS',
            responsible: 'ACK'
        },
        approve: {
            twr: 'ATC TWR user',
            aro: 'ATC ARO user'
        },
        duration: {
            m1: 'Please, add period for search!',
            m2: 'Defined period is wrong! Please, correct...'
        },
    },
    user: {
        add: 'ADD NEW USER',
        search: {
            name:'FULL NAME',
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            name: 'FULL NAME',
            username: 'USERNAME',
            role: 'ROLE',
            airport: 'AIRPORT',
            login: 'LAST LOGIN',
            active: 'ACTIVE',
            options: 'OPTIONS'
        }
    },
    superadminsettings: {
        add: 'ADD NEW AIRPORT',
        search: {
            date: 'DATE',
            type:'REPORT TYPE',
            airport:'ICAO LIST OF AIRPORTS',
            config:'CONFIGURATION',
            using_airport: 'PISTAMATIC AIRPORTS',
            rwy:'RWY APP',
            wl:'WL APP',
            paas:'PAAS APP',
            atc:'ATC ROLE',
            reporting: 'EMAIL NOTIFICATION',
            gps: 'GPS APP'
        },
        notification: {
            first: 'NOT SEND',
            second: 'SEND REP.',
            third: 'SEND INFO'
        },
        table: {
            airport: 'AIRPORT',
            added: 'LICENSE',
            version_RWY: 'Ver. RWY',
            version_WL: 'Ver. WL',
            version_PAAS: 'Ver. PAAS',
            remarks: 'REMARKS',
            rwyapp: 'RWY',
            wlapp: 'WL',
            paasapp:'PAAS',
            atc_role: 'ATC',
            notification: 'REPORT',
            options: 'OPTIONS',
            configuration: 'CONF',
            gps:'GPS'
        }
    },
    mailing_list: {
        add: 'ADD NEW MAIL',
        search: {
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            airport: 'AIRPORT',
            type: 'TYPE',
            label: 'LABEL NAME',
            mail: 'MAIL ADDRESS',
            options: 'OPTIONS'
        }
    },
    personal: {
        name: 'FULL NAME',
        username: 'USER NAME',
        mail: 'EMAIL',
        current_pass: 'CURRENT PASSWORD',
        new_pass: 'NEW PASSWORD',
        confirm_pass: 'CONFIRM PASSWORD'
    },
    usermanipulation: {
        name: 'FULL NAME',
        username: 'USER NAME',
        mail: 'EMAIL',
        role: 'ROLE',
        airport: 'AIRPORT',
        config: 'CONFIGURATION',
        rwy: 'RWY APPLICATION',
        wl: 'WILDLIFE APPLICATION',
        paas: 'PAAS APPLICATION',
        atc: 'ATC ROLE',
        gps: 'GPS FUNCTION'
    },
    airport_settings: {
        airport: 'AIRPORT',
        version_RWY: 'RWY VERSION',
        version_WL: 'WL VERSION',
        version_PAAS: 'PAAS VERSION',
        remarks: 'REMARKS'
    },
    log: {
        table: {
            message: 'MESSAGE',
            added: 'ADDED',
            options: 'OPTIONS'
        }
    },
    mail_manipulation: {
        airport: 'AIRPORT',
        label: 'LABEL NAME',
        mail: 'MAIL ADDRESS',
        type: 'TYPE OF MAILING LIST'
    },
    notification: {
        title: 'E-MAIL NOTIFICATION SET-UP ',
        first: 'NOT SENDING ',
        first_1: 'REPORT ONLY',
        second: 'SENDING ',
        second_1: 'EMAIL NOTIFICATION WITH REPORT',
        third: 'SENDING ',
        third_1: 'EMAIL NOTIFICATION WITHOUT REPORT',
        SOS: 'WILDLIFE SOS BUTTON STATISTICS',
        mailing:'MAILING LIST'
    },
    message_center: {
        airport: 'AIRPORT',
        title: 'TITLE',
        text: 'TEXT',
        all: 'ALL AIRPORTS',
        table: {
            airport: 'AIRPORT',
            date: 'DATE/TIME',
            title: 'TITLE',
            text: 'MESSAGE',
            send: 'STATUS'
        }
    },
    airport_map: {
        radio1: 'RCR',
        radio2: 'ADSIR'
    },
    visitor: {
        table: {
            text: 'COUNTRY/CITY',
            date: 'DATE/TIME',
            ip: 'IP ADDRESS'
        }
    },
    wl_stat: {
        typeanimal: 'TOTAL NUMBER OF SPECIES',
        typeanimalSearch_B:'NUMBER OF SELECTED BIRD RECORDED',
        typeanimalSearch_M:'NUMBER OF SELECTED MAMMAL RECORDED',
        typeanimalSearch:'NUMBER OF SELECTED SPECIES RECORDER',
        typeb: 'TOTAL NUMBER BY BIRDS SPECIES',
        typem: 'TOTAL NUMBER BY MAMMALS SPECIES',
        typenob: 'NUMBER OF BIRD SPECIES RECORDED',
        typenom: 'NUMBER OF MAMMAL SPECIES RECORDED',
        typeno: 'NUMBER OF SELECTED SPECIES RECORDED',
        typenoSearch_B: 'NUMBER OF SELECTED BIRD RECORDED',
        typenoSearch_M: 'NUMBER OF SELECTED MAMMAL RECORDED',
        timeb:'TIME WHEN BIRD SPECIES ARE RECORDED',
        timem:'TIME WHEN MAMMAL SPECIES ARE RECORDED',
        time:'TIME WHEN SELECTED SPECIES ARE RECORDED',
        timeSearch_B:'THE TIME OF THE DAY WHEN THE SELECTED BIRD IS RECORDED',
        timeSearch_M:'THE TIME OF THE DAY WHEN THE SELECTED MAMMAL IS RECORDED',
        monthb:'TIME WHEN BIRD SPECIES RECORDED BY MONTH',
        monthm:'TIME WHEN MAMMALS SPECIES RECORDED BY MONTH',
        month:'TIME WHEN SELECTED SPECIES RECORDED BY MONTH',
        monthSearch_B:'TIME WHEN SELECTED BIRD IS RECORDED BY MONTH',
        monthSearch_M:'TIME WHEN SELECTED MAMMAL IS RECORDED BY MONTH',
        monthSearch:'TIME WHEN SELECTED SPECIES IS RECORDED BY MONTH',
        wind:'BIRD SPECIES RECORDED IN CORRELATION WITH WIND DIRECTION ',
        windSearch:'SPECIES RECORDED IN CORRELATION WITH WIND DIRECTION ',
        activity:'BIRD SPECIES ACTIVITY ',
        activitySearch:'RECORDED ACTIVITIES OF SELECTED BIRD ',
        measureB:'MEASURES USED FOR BIRDS',
        measureSearch_B:'MEASURES USED FOR SELECTED BIRD',
        measureSearch_M:'MEASURES USED FOR SELECTED MAMMAL',
        resultB:'SUCCESS OF IMPLEMENTED MEASURES FOR BIRDS',
        resultSearch_B:'SUCCESS OF IMPLEMENTED MEASURES USED FOR SELECTED BIRD',
        resultSearch_M:'SUCCESS OF IMPLEMENTED MEASURES FOR SELECTED MAMMAL',
        measureM:'MEASURES USED FOR MAMMALS',
        measure:'MEASURES USED FOR SELECTED SPECIES',
        resultM:'SUCCESS OF IMPLEMENTED MEASURES FOR MAMMALS',
        result:'SUCCESS OF IMPLEMENTED MEASURES FOR SELECTED SPECIES',
        measureBirds:'MEASURES USED vs BIRD SPECIES ',
        measureMammals:'MEASURES USED vs MAMMAL SPECIES ',
        generalGridB: 'LOCATION WHERE BIRDS ARE RECORDED',
        generalGridM: 'LOCATION WHERE MAMMALS ARE RECORDED',
        gridB: 'LOCATION WHERE BIRD ARE RECORDED',
        gridM: 'LOCATION WHERE MAMMAL ARE RECORDED',
        grid: 'LOCATION WHERE SELECTED SPECIES ARE RECORDED',
        gridSearch_B: 'LOCATIONS WHERE THE SELECTED BIRD IS RECORDED ',
        gridSearch_M: 'LOCATIONS WHERE THE SELECTED MAMMAL IS RECORDED',
        search: {
            date: 'DATE',
            period:'PERIOD',
            birds:'BIRDS',
            mammals: 'MAMMALS',
            airport: 'AIRPORT'
        },
        general_chart: 'GENERAL CHARTS FOR ALL TYPE OF ANIMALS',
        search_chart: 'CHARTS FOR SEARCHING ANIMAL',
        listing: 'REPORT\'S LISTING',
        return_stat: 'WLS DIAGRAMS'
    },
    duration: {
        table: {
            date: 'DATE',
            duration: 'DURATION'
        }
    },
    birdsstrike: {
        operator: 'OPERATOR',
        model: 'AIRCRAFT MODEL',
        engine: 'ENGINE MODEL',
        registration: 'AIRCRAFT REGISTRATION',
        date: 'DATE',
        time: 'TIME',
        name: 'AERODROME NAME',
        runway: 'RUNWAY USED',
        location: 'LOCATION',
        height: 'HEIGHT (AGL) ft',
        speed: 'SPEED (IAS) kt',

        phase: 'PHASE OF FLIGHT',
        parked: 'PARKED',
        taxi: 'TAXI',
        takeoff: 'TAKE-OFF RUN',
        climb: 'CLIMB',
        enroute: 'EN ROUTE',
        descent: 'DESCENT',
        approach: 'APPROACH',
        landing: 'LANDING ROLL',

        partOfAircraft:'PART(S) OF AIRCRAFT',
        radome: 'RADOME',
        windshield: 'WINDSHIELD',
        nose: 'NOSE (EXCLUDING ABOVE)',
        engine1: 'ENGINE NO.1',
        engine2: 'ENGINE NO.2',
        engine3: 'ENGINE NO.3',
        engine4: 'ENGINE NO.4',
        propeller: 'PROPELLER',
        wing: 'WING/ROTOR',
        fuselage: 'FUSELAGE',
        landing_gear: 'LANDING GEAR',
        tail: 'TAIL',
        lights: 'LIGHTS',
        other: 'OTHER (SPECIFY)',
        struck: 'STRUCK',
        damaged: 'DAMAGED',

        effect: 'EFFECT ON FLIGHT',
        none: 'NONE',
        ab_takeoff: 'ABORTED TAKEOFF',
        pre_landing: 'PRECAUTIONARY LANDING',
        engine_shut: 'ENGINE SHUT DOWN',
        other_effect: 'OTHER (SPECIFY)',

        PartOfDay: 'PART OF DAY',
        zora: 'DAWN',
        dan:'DAY',
        noc: 'DUSK',
        sumrak: 'NIGHT',

        Cloudy: 'SKY CONDITION',
        vedro:'NO CLOUD',
        mala_obl: 'SOME CLOUD',
        puno_obl: 'OVERCAST',


        Drops: 'PRECIPITATION',
        kisa: 'RAIN',
        magla: 'FOG',
        snijeg: 'SNOW',

        mala: 'SMALL',
        srednja: 'MEDIUM',
        velika: 'LARGE',

        ptice: 'BIRD SPECIES',

        broj: 'NUMBER OF BIRDS',
        more: 'MORE THAN 100',
        none: 'NONE',
        seen: 'SEEN',
        broj1: '1',
        broj2: '2-11',
        broj3: '11-100',

        warned: 'PILOT WARNED OF BIRDS?',

        velicina: 'SIZE OF BIRDS',
       
        opis: 'REMARKS',

        da: 'YES',
        ne: 'NO',
        who_flight: 'FLIGHT CREW',
        who_ground: 'GROUND SERVICING PERSONEL',
        who:'WHO REPORTED THE BIRDSTRIKE?'
    },
    tracker: {
        search: {
            date: 'DATE',
            time_from:'TIME (FROM)',
            time_to:'TIME (TO)'
        },
    },
    paas: {
        no_alerts:'NO ACTIVE ALERT!!!',
        no_stopped_alerts:'NO TERMINATED ALERTS!!!',

        table: {
            type:'TYPE',
            illustration:'ILLUSTRATION',
            datetime:'TRIGGERING TIME',
            datetime_stopped:'TERMINATION TIME',
            created: 'CREATED BY',
            options: 'OPTIONS',
            stakeholders: 'STAKEHOLDERS',
            resp_person: 'RESP. PERSON',
            crisis_team:'CRISIS TEAM',
            finished: 'TERMINATED BY'
        },
        alerts: {
            create: '+',
            name_alert: 'GROUP OF ALERTS',
            contact: 'CONTACT\'S LIST',
            alert: 'ALERT',
            active: 'ACTIVE ALERTS',
            content: 'CONTENT OF INITIAL INFORMATION',
            classification: 'CLASSIFICATION OF  ALERT',
            classification_values: ['GENERAL & UNVERIFIED','DIRECT & REAL'],
            time_happen: 'TIME OF OCCURENCE',
            time_recorded: 'ALERT TRIGGERING TIME',
            airline_name:'AIRLINE NAME',
            airline_reg:'A/C REGISTRATION',
            no_passenger: 'No OF PASSENGERS',
            no_passenger_values:['UNDER 100', '100 TO 200', '200 TO 300', '300 TO 400'],
            no_crew:'No OF CREW',
            no_crew_values: ['3 TO 5', '6 TO 8', '9 TO 11', '11 TO 15'],
            dep:'A/D OF DEPARTURE',
            arr:'DESTINATION A/D',
            aircraft: 'A/C TYPE',
            target_flying: 'FLYING A/C AS A TARGET',
            target_flying_values: ['EN ROUTE','CRUISE','INITIAL DESCENT','APPROACH','GO-AROUND','LANDING'],
            target_ground: 'ON GROUND A/C AS A TARGET ',
            target_ground_values: ['RUNWAY','TURN PAD', 'TAXIWAY', 'A/C  ON PARKING POSITION', 'PASSENGER DISCHARGE ACTIVITY', 'PASSENGERS EMBARKING ACTIVITY', 'A/C ON THE BRIDGE', 'OPEN PARKING POSITION'],
            number_Passsenger: 'PASSENGERS  NUMBER IN TERMINAL',
            number_Passenger_Landside: 'TERMINAL LANDSIDE',
            number_Passenger_Airside: 'TERMINAL AIRSIDE',
            number_Passenger_values: ['< 1,000', '1,000 - 5,000', '5,000 - 10,000', '10,000 >'],
            aircraft_apron: 'NUMBER OF A/C ON THE APRON',
            vip: 'INTERESTING GOODS OR VIP IN A/C',
            vip_values: ['YES', 'NO'],
            no_attacker: 'TYPE & NUMBER OF ATTACKERS',
            no_attacker_values: ['KIDNAPPERS', 'SABOTERS', 'CALLER  UNKNOWN / ENEMY', 'UNKNOWN BAGGAGE OWNER', 'UNRULY PASSENGER'],
            name_attacker: 'ATTACKERS NAME',
            name_attacker_values: ['YES', 'NO'],
            attacker_demands: 'ATTACKERS DEMANDS',
            conseq: 'CONSEQUENCES FOR THE A/C',
            conseq_crew: 'CONSEQUENCES FOR THE CREW',
            conseq_pass: 'CONSEQUENCES FOR PASSENGERS IN TERMINAL',
            conseq_fac: 'CONSEQUENCES FOR THE A/D FACILITY',
            manner: 'MANNER AND MEANS OF EXECUTION',
            manner_values: ['USING FIREARMS', 'USING COLD WEAPONS', 'USING A BOMB', 'USING AN EXPLOSIVE DEVICE', 'USING PHYSICAL FORCE'],
            receive_info: 'METHOD OF RECEIVING INFORMATION',
            receive_info_values: ['TRANSPONDER CODE 7500', 'PHONE', 'MOBILE', 'RADIO DEVICES'],
            not_source: 'SOURCE FROM A/C IN A FLIGHT',
            not_source_values: ['CAPTAIN', 'FIRST OFFICER','CABIN CREW'],
            not_source_ground: 'SOURCE FROM THE ON GROUND A/C ',
            not_source_ground_values: ['CAPTAIN','CABIN CREW','FLIGHT CONTROL','AIRPORT OPC'],
            other_info: 'OTHER RELEVANT INFORMATIONS',
            if_yes: 'IF YES, OTHER SPECIFY',
            //no_kiddn: 'NUMBER OF KIDNAPPERS / ATTACKERS / SABOTERS',
            //korigovati naslov jer sam promijenio
            ac_location:'ATC BUILDING EVACUATION',
            ac_location_values:['ARO OFFICE','ATC TWR CONTROL','ATC APP CONTROL','METEO OFFICE','WHOLE BUILDING'],
            target: 'LOCATION OF A/D TERMINAL UNDER ATTACK ',
            target_land: 'LANDSIDE',
            target_land_values: ['TERMINAL HALL','CHECKIN','CAFETERIA','RESTAURANT','SHOPS','PASSENGER WELCOME AREA','LOST AND FOUND','POLICE BUILDING','HANGARS','VIP LOUNGE FACILITY','CUSTOMS BUILDING','FUEL STORAGE /TANK','FUEL TERMINAL','A/D VEHICLES','ELECTRICAL SYSTEMS','IT SYSTEMS','HEATING / COOLING SYSTEMS'],
            target_air: 'AIRSIDE',
            target_air_values: ['PASSPORT CONTROL DEPARTURES', 'DEPARTURE GATE','BOARDING CHECK POINT','A/C BRIDGE','A/D BUS','BUSSINES LOUNGE','VIP LOUNGE','CAFETERIA','RESTAURANT','APRON','CUSTOMS AREA - DEPARTURE','ARRIVAL GATE','PASSPORT CONTROL ARRIVALS','BAGGAGE CLAIM AREA','CUSTOMS AREA - ARRIVALS'],
            people_target: 'PEOPLE AS TARGETS OF ATTACKS',
            people_target_values: ['CABIN CREW','FLIGHT CREW','PASSENGERS IN THE A/C','AIRPORT STAFF','OTHER PASSENGERS IN THE TERMINAL','SECURITY OFFICER','VISITORS','WORKERS OF RENTED PREMISES','CUSTOMS OFFICER','POLICE STAFF','CHECKIN STAFF','BOARDING STAFF','VIP PASSENGERS','BUSSINES PASSENGERS','DUTY FREE SHOP STAFF','ATC STAFF'],
            evacuation: 'A/C EVACUATION',
            evacuation_values: ['A/C DISLOCATION','ISOLATED PARKING POSITION','EVACUATION OF PASSENGERS','A/C CREW EVACUATION','UNLOADING BAGGAGE'],
            evacuation_building: 'A/D BUILDING EVACUATION',
            evacuation_building_land: 'PASSENGER TERMINAL - LANDSIDE',
            evacuation_building_land_values: ['TERMINAL HALL','PASSENGER WELCOME AREA','HANGARS','CHECKIN','LOST AND FOUND','VIP LOUNGE FACILITY','CAFETERIA','ATC TOWER','CUSTOMS BUILDING','RESTAURANT','POLICE BUILDING','FUEL TERMINAL','SHOPS LANDSIDE','COMMERCIAL CAR PARKING','CARGO WAREHOUSE'],
            evacuation_building_air: 'PASSENGER TERMINAL - AIRSIDE',
            evacuation_building_air_values: ['PASSPORT CONTROL DEPARTURES','BUSSINES LOUNGE','CUSTOMS AREA - DEPARTURE','DEPARTURE GATE','VIP LOUNGE','ARRIVAL GATE','BOARDING CHECK POINT','CAFETERIA','PASSPORT CONTROL ARRIVALS','AIRCRAFT BRIDGE','RESTAURANT','BAGGAGE CLAIM AREA','AIRPORT BUS','CUSTOMS AREA - ARRIVALS','SHOPS AIRSIDE'],
            ac_size:'A/C SIZE',
            ac_size_values: ['SMALL A/C - A/C WITH LESS THAN 3 PASSENGERS','LARGE A/C - A/C WITH MORE THAN 3 PASSENGERS'],
            start_alarm_LS: 'START ALARM LOCAL STANDBY',
            start_alarm_1_values: ['FEATHERED PROPELLER ON A MULTI-ENGINE A/C','OVERHEATED ENGINE','OIL LEAK','RADIO FAILURE/LOST COMMUNICATION','50% OR MORE A/C ENGINES ARE OFF'],
            start_alarm_FULL_EME: 'START ALARM FULL EME',
            start_alarm_2_values: ['ON-BOARD SMOKE OR FIRE','FAULTY LANDING GEAR','LOW HYDRAULIC PRESSURE','ENGINE(S) INOPERABILITY WHEN MORE THAN 50% OF A/C ENGINES','RADIO FAILURE/LOSS OF COMMUNICATION'],
            start_alarm_3_values: ['EARTHQUAKE','FLOOD','FIRE','INITIAL FIRES AT AIRPORT FACILITIES'],
            type_naturalDisaster:'TYPE OF NATURAL DISASTER',
            type_naturalDisaster_values: ['EARTHQUAKE','FLOOD','FIRES','INITIAL FIRES AT A/D FACILITY'],
            phase_flight: 'PHASE OF FLIGHT',
            phase_flight_values: ['CRUISE / EN ROUTE','INITIAL DESCENT','APPROACH','GO-AROUND','LANDING'],
            start_crash_alarm: 'START CRASH ALARM',
            start_crash_alarm_values: ['ELT','MEDICAL EMERGENCY ONBOARD','SEVERE ICING','LOSS OF COMMUNICATION','ENGINE FAILURE','FLIGHT CONTROL PROBLEMS','REPORT OF AN ACCIDENT','LOW/MINIMUM FUEL','CONFIRMATION AN INCIDENT OCCURED','BIRD STRIKE','AIRFRAME DAMAGE','DEPRESSURIZATION','FIRE'],
            conseq_atc_fac: 'CONSEQUENCES FOR THE ATC FACILITY',
            attack_target: 'ATTACK TARGET IS AN ATC FACILITY',
            attack_target_values:['ATC BUILDING','NAVAIDS'],
            ad_departure: 'AIRPORT OF DEPARTURE',
            
            alert_position: 'MOVE CURSOR TO ALERT\'S POSITION',
        },
    },
    paas_contact:{
        add:'ADD NEW CONTACT',
        type:'PERSON\'s ROLE',
        type:'TYPE',
        role:'ROLE',
        name:'CONTACT\'s NAME',
        phone: 'CONTACT\'s PHONE NUMBER',
        table: {
            typename: 'TYPE',
            rolename: 'ROLE',
            name:'NAME',
            phone:'PHONE NUMBER'
        },
    },
    listing:{
        total:'TOTAL NUMBER OF GENERATED REPORTS:',
        table: {
            date: 'DATE',
            times:'TIMES',
            count:'COUNT'
        }
    },
    correction_number: {
        title: 'RCR and ADSIR NUMBER CORRECTION',
        rcr:'RCR',
        adsir: 'ADSIR'
    }
}